import React from 'react'
import styled from 'styled-components'

export default ({ children, label, onToggle, isOpen, currIndex, index }) => {
  return (
    <Accordion>
      <Label
        isOpen={isOpen}
        onClick={() => onToggle(currIndex === index ? null : index)}
      >
        {label}
      </Label>
      <Body isOpen={isOpen}>{children}</Body>
    </Accordion>
  )
}

const Label = styled.div`
  border-top: 1px solid
    ${props =>
      props.isOpen
        ? props.theme.colors.highlight1
        : props.theme.colors.greyDark2};

  padding: 20px 0 20px 0;
  cursor: pointer;
  position: relative;
  &:hover {
    border-top: 1px solid
      ${props =>
        props.isOpen
          ? props.theme.colors.highlight1
          : props.theme.colors.white};
  }
  &:after {
    position: absolute;
    right: 2px;
    top: 23px;
    display: block;
    content: ' ';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid
      ${props =>
        props.isOpen
          ? props.theme.colors.highlight1
          : props.theme.colors.greyDark2};
    transform: ${props => (props.isOpen ? `rotate(180deg)` : `rotate(0deg)`)};
  }
`

const Accordion = styled.div``

const Body = styled.div`
  display: ${props => (props.isOpen ? `block` : `none`)};
  padding: 10px 0 40px 0;
`
