import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Accordion from './accordion'
import { Copy } from '../page/components'
import { Flex, Box } from 'reflexbox'

export default ({ isAccount }) => {
  const [currIndex, setCurrIndex] = useState(null)

  return (
    <Flex flexWrap="nowrap">
      <Box width="100%">
        <Copy>
          <p>
            Take a look at some of the most common support topics below or{' '}
            <a href="mailto:info@shillingtoneducation.com">email us</a> with any
            additional questions.
            <br />
            <br />
          </p>
        </Copy>
        <Wrap>
          <Accordion
            label="How does The Guide work?"
            onToggle={setCurrIndex}
            isOpen={currIndex === 0}
            currIndex={currIndex}
            index={0}
          >
            <Copy>
              <p>
                The Guide is Shillington&rsquo;s exclusive online platform, the
                go-to spot for all course materials and bonus resources.
              </p>
              <ul>
                <li>
                  <strong>Current students</strong> will gain access to content
                  as they progress throughout the course. During the course,
                  your teachers will assign and direct you to relevant pages
                  within The Guide. You can quickly locate pages by entering the
                  unique reference code (e.g. FD01) in the search bar.
                </li>
                <li>
                  <strong>Graduates</strong> who sign up for a paid annual
                  subscription will have complete access to the course
                  content—and more!
                </li>
              </ul>
            </Copy>
          </Accordion>
          <Accordion
            label="Can I print and/or download course material?"
            onToggle={setCurrIndex}
            isOpen={currIndex === 1}
            currIndex={currIndex}
            index={1}
          >
            <Copy>
              <h4>Printing</h4>
              <p>
                You can print any page by pressing CMD+P. However, we recommend
                that you access the content digitally because The Guide
                incorporates animations and videos as teaching tools.
                You&rsquo;ll have the most complete experience by viewing The
                Guide digitally on desktop or mobile devices
              </p>
              <h4>Downloading</h4>
              <p>
                You can download all PDF assets linked to course content (e.g.
                program shortcuts, research templates, etc.) for future
                reference.
              </p>
              <p>
                But—to protect the intellectual property and integrity of our
                curriculum—the core course content and lecture materials are not
                available for direct download. You can always find this content
                by logging into The Guide.
              </p>
            </Copy>
          </Accordion>
          <Accordion
            label="Can I access course material forever?"
            onToggle={setCurrIndex}
            isOpen={currIndex === 2}
            currIndex={currIndex}
            index={2}
          >
            <Copy>
              <p>
                While you are enrolled at Shillington as a student, you will
                have full access to The Guide.
              </p>
              <p>
                Once you graduate, you will continue to have full access to The
                Guide for four weeks. After the four weeks expire, you will be
                prompted to sign up for a paid annual subscription.
              </p>
            </Copy>
          </Accordion>
          <Accordion
            label="Can anyone access The Guide?"
            onToggle={setCurrIndex}
            isOpen={currIndex === 3}
            currIndex={currIndex}
            index={3}
          >
            <Copy>
              <p>
                Subscription to The Guide is exclusive to Shillington. Only
                current students or graduates can have access.
              </p>
            </Copy>
          </Accordion>
          <Accordion
            label="Will the material be updated?"
            onToggle={setCurrIndex}
            isOpen={currIndex === 4}
            currIndex={currIndex}
            index={4}
          >
            <Copy>
              <p>
                Yes! The Guide will always reflect the most up-to-date content
                being taught in Shillington classrooms around the world. Our
                curriculum evolves with the design industry, so The Guide will
                be updated with any new material or best practices. Since
                industry software is frequently updated, tutorials on The Guide
                will be updated to reflect changes.
              </p>

              <p>
                If you have feedback or suggestions for new materials or
                features, e-mail us at{' '}
                <a href="mailto:info@shillingtoneducation.com">
                  info@shillingtoneducation.com
                </a>
                .
              </p>
            </Copy>
          </Accordion>
          <Accordion
            label="Can I change my account details?"
            onToggle={setCurrIndex}
            isOpen={currIndex === 6}
            currIndex={currIndex}
            index={6}
          >
            <Copy>
              <p>
                Of course! Go to your Account Settings and you will be able to
                change any appropriate information.
              </p>
            </Copy>
          </Accordion>
          <Accordion
            label="Who do I contact if I have an issue?"
            onToggle={setCurrIndex}
            isOpen={currIndex === 7}
            currIndex={currIndex}
            index={7}
          >
            <Copy>
              <p>
                If you are having technical difficulties, go to the{' '}
                <Link to={isAccount ? `/account-support` : `/support`}>
                  support
                </Link>{' '}
                section of The Guide to troubleshoot independently at any time.
              </p>

              <p>
                For direct assistance, please email{' '}
                <a href="mailto:info@shillingtoneducation.com">
                  info@shillingtoneducation.com
                </a>
                . You can expect a response within 24-48 hours.
              </p>
            </Copy>
          </Accordion>
          <Accordion
            label="Can I cancel my subscription?"
            onToggle={setCurrIndex}
            isOpen={currIndex === 8}
            currIndex={currIndex}
            index={8}
          >
            <Copy>
              <p>
                You can cancel your subscription at any time. Go to your Account
                Settings for more information and to cancel your subscription.
              </p>
            </Copy>
          </Accordion>
          <Accordion
            label="Can I permanently delete my theguide.design account?"
            onToggle={setCurrIndex}
            isOpen={currIndex === 9}
            currIndex={currIndex}
            index={9}
          >
            <Copy>
              <p>
                You can permanently delete your account at any time. Go to your
                Account Settings for more information and to delete your
                account. Some information you&rsquo;ve provided for us when you
                signed up for theguide.design, as well as your official student
                records will remain in the Shillington database. Any changes
                you&rsquo;ve made to your personal profile (profile photograph,
                biography, contact information, payment methods, etc.) will be
                lost if you close your account. Should ever chose to sign up
                again, you will have to start the process from the beginning.
              </p>
            </Copy>
          </Accordion>
        </Wrap>
      </Box>
    </Flex>
  )
}

const Wrap = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.greyDark2};
`
